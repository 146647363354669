import React from "react";
import PropTypes from "prop-types";
import { getUser, getPages } from "../services/auth";

const initialState = {
  user: null,
  isLoggedIn: undefined,
  userRefresh: 0,
  pageCache: {},
  pages: {
    whereId: {},
    uriToId: {},
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_USER": {
      return {
        ...state,
        user: action.value,
        isLoggedIn: action.value?.nicename !== undefined,
      };
    }
    case "CHECK_USER": {
      return {
        ...state,
        userRefresh: state.userRefresh + 1,
      };
    }
    case "CACHE_PAGE": {
      const newState = { ...state };
      newState.pageCache[action.uri] = action.data;
      return newState;
    }
    case "SET_PAGES": {
      return {
        ...state,
        pages: action.value,
      };
    }
    default:
      throw new Error(`Invalid action: ${action.type}`);
  }
};

export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    getUser()
      .then((value) => dispatch({ type: "SET_USER", value }))
      .catch(() => null);
  }, [state.userRefresh]);

  React.useEffect(() => {
    if (state.isLoggedIn) {
      getPages()
        .then((pages) => {
          import("../utils/icon-json.js").then(({ iconJsonFromClass }) => {
            dispatch({
              type: "SET_PAGES",
              value: pages?.reduce(
                (newPages, page) => {
                  newPages.uriToId[page.uri] = page.id;
                  newPages.whereId[page.id] = {
                    ...page,
                    children: page.children.nodes.sort((a, b) =>
                      (a.menuOrder || 0) <= (b.menuOrder || 0) ? -1 : 1
                    ),
                    icon: page.icon?.className
                      ? iconJsonFromClass(page.icon.className)
                      : null,
                  };
                  return newPages;
                },
                { uriToId: {}, whereId: {} }
              ) || { uriToId: {}, whereId: {} },
            });
          });
        })
        .catch(() => null);
    }
  }, [state.isLoggedIn]);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

GlobalContextProvider.propTypes = {
  children: PropTypes.node,
};

export default GlobalContextProvider;
