export const graphqlClient = (url) => ({
  graphql:
    (templateQuery) =>
    (request = {}) =>
      fetch(url, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          ...request,
          query: `${request.query || ""}${templateQuery}`,
        }),
        headers: {
          "content-type": "application/json",
        },
      }).then((response) => response.json()),
});
