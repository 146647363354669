import { graphqlClient } from "../utils/graphql-client";

const { graphql } = graphqlClient(
  `${process.env.GATSBY_WORDPRESS_URL}/graphql`
);

export const getUser = () => {
  const viewer = graphql`
    query Viewer {
      viewer {
        nicename
      }
    }
  `;

  return viewer().then((response) => response?.data?.viewer);
};

export const getPages = () => {
  const pages = graphql`
    query Pages {
      pages(where: { stati: [PUBLISH, PRIVATE] }) {
        nodes {
          id
          children(where: { stati: [PUBLISH, PRIVATE] }) {
            nodes {
              ... on Page {
                id
                menuOrder
              }
            }
          }
          parentId
          title
          uri
          icon {
            className: class
          }
        }
      }
    }
  `;

  return pages().then((response) => response?.data?.pages.nodes);
};

export const handleLogin = (
  dispatch,
  { username, password, onSuccess, onFailure, rememberLogin = false }
) => {
  const login = graphql`
    mutation Login(
      $username: String!
      $password: String!
      $rememberLogin: Boolean!
    ) {
      login(
        input: {
          password: $password
          user: $username
          rememberLogin: $rememberLogin
        }
      ) {
        status
      }
    }
  `;

  login({
    variables: {
      username,
      password,
      rememberLogin,
    },
  })
    .then((response) => {
      if (response?.data?.login.status === "success") {
        dispatch({ type: "CHECK_USER" });
        if (onSuccess) {
          onSuccess();
        }
      } else {
        if (onFailure) {
          onFailure(response?.data?.login.status);
        }
      }
    })
    .catch((error) => onFailure(error));
};

export const isLoggedIn = (state) => {
  return state.isLoggedIn === true;
};

export const logout = (dispatch, callback) => {
  const logout = graphql`
    mutation Logout {
      logout(input: {}) {
        status
      }
    }
  `;
  logout().then((response) => {
    if (response?.data?.logout.status === "success") {
      dispatch({ type: "CHECK_USER" });
      if (callback) {
        callback();
      }
    }
  });
};
